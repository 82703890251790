import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Chip from "./chip";
import theme from "./theme.json";

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  // height: 400px;
  width: calc(360px - 36px);
  min-width: calc(360px - 36px);
  @media (min-width: 888px) {
    width: 360px;
    min-width: 360px;
  }
`;

const GridCard = styled(Link)`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: ${theme.color.background};
  border-radius: 16px;
  transform: scale(1);
  transition: ease-in-out 0.2s;
  // border: 1px solid rgba(0, 0, 0, 0.05);
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  ${Wrapper}:hover & {
    transform: scale(1.03);
    // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
`;

const ImageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 24px;
  margin-bottom: 24px;
  height: 200px;
  // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  // background-color: white;
`;

const Image = styled.img`
  margin-top: 60px;
  width: 200%;
  padding-bottom: 24px;
  // position: absolute;
  // top: 16px;
  // bottom: 16px;
  // height: 100%;
  // max-height: 500px;
  // aspect-ratio: 1.1;
  // transform: scale(0.9);
  // transition: 0.25s ease-in-out;
  // ${Wrapper}:hover & {
  //   transform: scale(0.97);
  // }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  // border-top: 1px solid ${theme.color.line.normal};
  // padding-top: 24px;
`;

const Tier = styled.div`
  position: relative;
  width: 100%;
  // top: 24px;
  // left: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: ${theme.color.text.dark};
`;

const Map = styled.img`
  // position: absolute;
  // top: 24px;
  // right: 24px;
  height: 28px;
  margin-right: 12px;
`;

const Date = styled.div`
  // position: absolute;
  // top: 24px;
  // right: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: ${theme.color.text.light};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  color: ${theme.color.text.dark};
  margin-bottom: 12px;
  height: 64px;
  overflow: hidden;
  // ${Wrapper}:hover & {
  //   color: ${theme.color.primary.dark};
  // }
`;

const ArticleType = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: ${theme.color.text.dark};
  margin-bottom: 12px;
`;

const Lock = styled.img`
  margin-left: 4px;
  height: 10px;
`;

const ArticleCard = (props) => {

  const { articleData, userData } = props;

  let unlocked = false;

  if (articleData.free_sample === 1) {
    unlocked = true;
  }

  if (userData) {
    if (articleData.tier === "Fan" && (userData.basic || userData.standard || userData.premium)) {
      unlocked = true;
    }
    if (articleData.tier === "Insider" && (userData.standard || userData.premium)) {
      unlocked = true;
    }
    if (articleData.tier === "Analyst" && userData.premium) {
      unlocked = true;
    }
  }

  let tier =
    <Tier>
      The {articleData.tier} <Lock src="/small-icons/locked.svg" />
    </Tier>

  if (unlocked) {
    tier =
      <Tier>
        The {articleData.tier}
      </Tier>
  }

  if (articleData.free_sample) {
    tier =
      <Tier to="/subscribe">
        Free
      </Tier>
  }

  let map;

  if (articleData.country === "England") {
    map =
      <Map src="/maps/england-map.svg" />
  }

  if (articleData.country === "Spain") {
    map =
      <Map src="/maps/spain-map.svg" />
  }

  if (articleData.country === "Germany") {
    map =
      <Map src="/maps/germany-map.svg" />
  }

  if (articleData.country === "Italy") {
    map =
      <Map src="/maps/italy-map.svg" />
  }

  if (articleData.country === "France") {
    map =
      <Map src="/maps/france-map.svg" />
  }

  if (articleData.country === "United States") {
    map =
      <Map src="/maps/united-states-map.svg" />
  }

  let date =
    <Date>
      {articleData.date}
    </Date>

  return (
    <Wrapper>
      <GridCard to={articleData.link}>
        {tier}
        <ImageWrapper>
          <Image src="/example-plot.png" />
        </ImageWrapper>
        < TextWrapper >
          {/* <League>
            {articleData.league}
          </League> */}
          <ArticleType>
            {map} {articleData.type}
          </ArticleType>
          < Title >
            {articleData.title}
          </Title >
          <Date>
            {articleData.date}
          </Date>
        </TextWrapper >
      </GridCard >
    </Wrapper >
  )
}

export default ArticleCard;
