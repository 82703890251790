import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderWrapper } from "./wrapper-elements";
import styled from "styled-components";
import Button from "./button";
import theme from "./theme";

const Group = styled.div`
  // position: absolute;
  position: relative;
  z-index: 1000;
  // box-sizing: border-box;
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
`;

const DrawerWrapper = styled.div`
  display: ${props => props.open ? "flex" : "none"};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
`;

const MenuButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin-left: 32px;
  padding: 0px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const Icon = styled.img`
  height: 24px;
`;

const LogoWrapper = styled(Link)`
  height: 48px;
  margin-bottom: 16px;
  @media (min-width: 888px) {
    margin-bottom: 0px;
  }
`;

const Logo = styled.img`
  height: 100%;
`;

const ButtonWrapper = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 888px) {
    margin-left: 32px;
    margin-right: 0px;
  }
`;

// const DrawerLogo = styled.img`
//   margin-top: 8px;
//   margin-left: 16px;
//   height: 40px;
// `;

// const NavigationCards = styled.div`
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   padding-top: 200px;
//   padding-bottom: 200px;
// `;

// const NavigationWrapper = styled.div`
//   position: fixed;
//   left: 100px;
//   bottom: 100px;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   align-items: center;
//   height: 50px;
// `;

// const NavigationButton = styled(Link)`
//   font-size: 16px;
//   font-weight: 600;
//   color: rgba(0, 0, 0, 0.3);
//   margin-right: 32px;
//   transition-duration: 0.2s;
//   &:hover {
//     color: rgba(0, 0, 0, 0.85);
//   }
// `;

// const SocialWrapper = styled.div`
//   position: fixed;
//   right: 100px;
//   bottom: 100px;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   align-items: center;
//   height: 50px;
// `;

// const IconButton = styled.button`
//   height: 20px;
//   margin-left: 16px;
//   margin-right: 16px;
//   background: unset;
//   cursor: pointer;
//   padding: 0px;
//   opacity: 0.3;
//   transition-duration: 0.2s;
//   &:hover {
//     opacity: 0.85;
//   }
// `;

// const Card = styled(Link)`
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   transform: skew(-10deg);
//   margin-left: 8px;
// `;

// const CardContent = styled.div`
//   transform: skew(10deg);
//   text-align: center;
//   font-size: 48px;
//   font-weight: 800;
//   color: white;
//   opacity: 1;
//   transition-duration: 0.2s;
//   width: 350px;
//   &:hover {
//     opacity: 0.7;
//   }
// `;

// const Wrapper = styled.div`
//   width: 100%;
// `;

const Header = (props) => {

  const { userData, signOut } = props;

  const [open, setOpen] = React.useState(false);

  let buttons;

  if (userData) {
    buttons =
      <Group>
        <ButtonWrapper>
          <Button style="outlined" corners="pill" inverted to="/subscribe" label="Subscribe" />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button style="text" corners="square" small inverted label="Account" to="/account" />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button style="text" corners="square" small inverted label="Sign out" onClick={signOut} />
        </ButtonWrapper>
      </Group>
  } else {
    buttons =
      <Group>
        <ButtonWrapper>
          <Button style="outlined" corners="pill" inverted to="/subscribe" label="Subscribe" />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button style="text" corners="square" small inverted to="/sign-up" label="Sign up" />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button style="text" corners="square" small inverted to="/sign-in" label="Sign in" />
        </ButtonWrapper>
      </Group>
  }


  // const [open, setOpen] = React.useState(false);

  // let backgroundColor;
  // let logoFile;
  // if (logoTheme === "white") {
  //   backgroundColor = "white";
  //   logoFile = "/HGIMT-logo.svg";
  // }
  // if (logoTheme === "dark") {
  //   backgroundColor = "rgba(38, 38, 38, 1)";
  //   logoFile = "/HGIMT-logo-light.svg";
  // }
  // if (logoTheme === "primary") {
  //   backgroundColor = primary;
  //   logoFile = "/HGIMT-logo-light.svg";
  // }

  return (
    <HeaderWrapper>
      <Group>
        <LogoWrapper to="/">
          <Logo src="HGIMT-logo-white.svg" />
        </LogoWrapper>
        {/* <MenuButton>
          <Icon src={open ? "/small-icons/close.svg" : "/small-icons/menu.svg"} onClick={() => setOpen(!open)} />
        </MenuButton> */}
      </Group>
      {buttons}
      <DrawerWrapper open={open}>

        {/* <NavigationCards>
          <Card style={{ background: "#6B88C2" }} to="/" onClick={() => setOpen(false)}>
            <CardContent>
              Latest <br /> articles
            </CardContent>
          </Card>
          <Card style={{ background: "#9EAED6" }} to="/leagues" onClick={() => setOpen(false)}>
            <CardContent>
              Browse <br /> by league
            </CardContent>
          </Card>
          <Card style={{ background: "#E97082" }} to="/subscribe" onClick={() => setOpen(false)}>
            <CardContent>
              Subscribe <br /> for $2
            </CardContent>
          </Card>
        </NavigationCards>
        <NavigationWrapper>
          <NavigationButton to="/about" onClick={() => setOpen(false)}>
            About
          </NavigationButton>
          <NavigationButton to="/sign-up" onClick={() => setOpen(false)}>
            Sign up
          </NavigationButton>
          <NavigationButton to="/sign-in" onClick={() => setOpen(false)}>
            Sign in
          </NavigationButton>
        </NavigationWrapper>
        <SocialWrapper>
          <IconButton>
            <Image src="social-logos/facebook-logo.svg" />
          </IconButton>
          <IconButton>
            <Image src="social-logos/instagram-logo.svg" />
          </IconButton>
          <IconButton>
            <Image src="social-logos/threads-logo.svg" />
          </IconButton>
          <IconButton>
            <Image src="social-logos/x-logo.svg" />
          </IconButton>
          <IconButton>
            <Image src="social-logos/github-logo.svg" />
          </IconButton>
          <Link to="/" onClick={() => setOpen(false)}>
            <DrawerLogo src="/HGIMT-logo.svg" />
          </Link>
        </SocialWrapper> */}
      </DrawerWrapper>
    </HeaderWrapper>
  );
}

export default Header;
