import styled from "styled-components";
import { Link } from "react-router-dom";
import theme from "./theme.json";

const Chip = styled(Link)`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.color.paragraph};
  background-color: ${theme.color.background};
  // border: 1px solid ${theme.color.line};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 10px;
  margin-bottom: 12px;
  border-radius: 50px;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
`;

export default Chip;
