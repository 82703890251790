import styled from "styled-components";
import { Link } from "react-router-dom";
import theme from "./theme.json";

const Base = styled.button`
  font-size: ${props => props.$small ? "14px" : "16px"};
  font-weight: 400;
  height: ${props => props.$small ? "40px" : "48px"};
  padding-left: ${props => props.$small ? "18px" : "24px"};
  padding-right: ${props => props.$small ? "18px" : "24px"};
  cursor: pointer;
  background-clip: padding-box;
  white-space: nowrap;
  transition: 0.3s ease-in-out;
`;

const Outlined = styled(Base)`
  color: ${props => props.$inverted ? "white" : theme.color.background_dark};
  // background: ${props => props.$inverted ? "rgba(40, 40, 45, 0)" : ""};
  border: 1.5px solid ${props => props.$inverted ? "white" : theme.color.background_dark};
  &:hover {
    background: ${props => props.$inverted ? "white" : theme.color.background_dark};
    color: ${props => props.$inverted ? theme.color.background_dark : "white"};
  }
`;

const Solid = styled(Base)`
  color: #FFFFFF;
  background: ${props => props.$inverted ? "rgba(40, 40, 45, 0)" : ""};
  border: 1.5px solid ${props => props.$inverted ? "white" : theme.color.neutral.dark};
  &:hover {
    background: ${props => props.$inverted ? "white" : theme.color.neutral};
    color: ${theme.color.text.dark};
`;

// const Text = styled(Base)`
//   color: ${props => props.$primary ? theme.color.primary.dark : theme.color.neutral.dark};
//   background: ${props => props.$primary ? theme.color.primary.light : theme.color.neutral.light};
//   &:hover {
//     background: ${props => props.$primary ? theme.color.primary.light_hover : theme.color.neutral.light_hover};
//   }
// `;

// const Base = styled.button`
//   font-size: ${props => props.$small ? "14px" : "16px"};
//   font-weight: 500;
//   height: ${props => props.$small ? "44px" : "56px"};
//   padding-left: ${props => props.$small ? "22px" : "24px"};
//   padding-right: ${props => props.$small ? "22px" : "24px"};
//   cursor: pointer;
//   background-clip: padding-box;
//   white-space: nowrap;
// `;

// const Solid = styled(Base)`
//   color: #FFFFFF;
//   background: ${props => props.$inverted ? "rgba(255, 255, 255, 1)" : theme.color.neutral.dark };
//   &:hover {
//     background: ${props => props.$inverted ? "rgba(255, 255, 255, 0.8)" : theme.color.neutral.dark_hover };
//   }
// `;

// const Outlined = styled(Base)`
//   color: ${props => props.$inverted ? theme.color.primary.dark : theme.color.neutral.dark};
//   background: unset;
//   border: 1.5px solid ${props => props.$inverted ? "rgba(255, 255, 255, 1)" : theme.color.neutral.dark};
//   padding-top: ${props => props.$small ? "8.5px" : "16.5px"};
//   padding-bottom: ${props => props.$small ? "8.5px" : "16.5px"};
//   padding-left: ${props => props.$small ? "12.5px" : "22.5px"};
//   padding-right: ${props => props.$small ? "12.5px" : "22.5px"};
//   &:hover {
//     background: ${props => props.$inverted ? "rgba(255, 255, 255, 0.1)" : theme.color.neutral.light};
//   }
// `;

const Text = styled.button`
  font-size: ${props => props.$small ? "14px" : "16px"};
  font-weight: 400;
  cursor: pointer;
  padding: 0px;
  white-space: nowrap;
  color: ${props => props.$inverted ? "rgba(255, 255, 255, 1)" : theme.color.neutral.dark };
  padding-top: 5.5px;
  padding-bottom: 4px;
  // padding-left: 4px;
  // padding-right: 4px;
  line-height: 1;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0);
  &:hover {
    // color: ${props => props.$inverted ? theme.color.neutral.dark_hover : theme.color.neutral.dark_hover };
    border-bottom: 1.5px solid ${props => props.$inverted ? "white" : theme.color.neutral.dark };
  }
`;

const Button = (props) => {

  const { style, primary, corners, small, label, to, onClick, inverted } = props;

  let button;

  let borderRadius;

  if (corners === "square") {
    borderRadius = "0px";
  }

  if (corners === "rounded") {
    borderRadius = "6px";
  }

  if (corners === "pill") {
    borderRadius = "50px";
  }

  if (style === "solid") {
    button =
      <Solid $primary={primary} $small={small} $inverted={inverted} style={{ borderRadius: borderRadius }} onClick={onClick}>
        {label}
      </Solid>
  }

  if (style === "outlined") {
    button =
      <Outlined $primary={primary} $small={small} $inverted={inverted} style={{ borderRadius: borderRadius }} onClick={onClick}>
        {label}
      </Outlined>
  }

  if (style === "text") {
    button =
      <Text $primary={primary} $small={small} $inverted={inverted} style={{ borderRadius: borderRadius }} onClick={onClick}>
        {label}
      </Text>
  }

  let wrapper;
  if (to) {
    wrapper =
      <Link to={to}>
        {button}
      </Link>
  } else {
    wrapper = button;
  }

  return (
    wrapper
  )
}

export default Button;
