import React from "react";
import { PageWrapper, ContentWrapper } from "./wrapper-elements";
import { Title, Paragraph, TextLink } from "./text-elements";
import { FormInstructions } from "./form-elements";
import SignInForm from "./sign-in-form";

const SignInPage = (props) => {

  const { API, saveUserData } = props;

  return (
    <PageWrapper $paddingTop>
      <ContentWrapper>
        <Title $centered>
          Sign in to enjoy our industry-leading, independent football data journalism!
        </Title>
        <FormInstructions $centered>
          <span>Don't have an account? <TextLink to="/sign-up">Sign up</TextLink> for free today to access featured articles!</span>
        </FormInstructions>
      </ContentWrapper >
      <SignInForm API={API} saveUserData={saveUserData} to="/" />
    </PageWrapper>
  )
}

export default SignInPage;
