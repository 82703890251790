import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "./button";
import Section from "./section";
import { PageWrapper, TitleWrapper, BannerWrapperTitle, GridWrapper } from "./wrapper-elements";
import theme from "./theme";
// import Dialog from "./dialog";
import Axios from "axios";

const data = [
  {
    "name": "The Fan",
    "price_id_monthly": "price_1Nv0FpJEOuFoLCZqi1n6uqEd",
    "price_id_yearly": "price_1Nv47LJEOuFoLCZqiDNzkGxl",
    "price_monthly": "$2",
    "price_yearly": "$20",
    "features": [
      "No ads",
      "Match previews: industry-leading AI-powered predictions for every league match",
      "Match reviews: ratings of how entertaining each match was, entertainment compared to expectations",
      "Match breakdowns: ",
      "League predictions each week",
      "10x tactical breakdowns /w"
    ],
    "button_label": "Subscribe"
  },
  {
    "name": "The Insider",
    "price_id_monthly": "price_1NxIhfJEOuFoLCZq38HXdBN3",
    "price_id_yearly": "price_1OFJZPJEOuFoLCZq9cE3vAwv",
    "price_monthly": "$5",
    "price_yearly": "$50",
    "features": [
      "All Fan-tier content",
      "Match previews",
      "Predictions and predicted goals for every match",
      "Post-match breakdowns",
      "ML-powered league table predictions",
      "Weekly in-depth team feature",
      "Match breakdowns",
      "Viewer guide",
      "Team power rankings (weekly)",
      "Player power rankings (weekly)",
    ],
    "button_label": "Subscribe"
  },
  {
    "name": "The Analyst",
    "price_id_monthly": "price_1Nv4aoJEOuFoLCZqWGFjN6iT",
    "price_id_yearly": "price_1Nv4aoJEOuFoLCZqt2DYE4gG",
    "price_monthly": "$10",
    "price_yearly": "$100",
    "features": [
      "All Insider-tier content",
      "Quantitative tactical analysis (M)",
      "Visual quantitative analysis for each player",
      "Player style analysis",
      "Set piece dependency",
      "Home team advantage",
      "Team style analysis",
    ],
    "button_label": "Subscribe"
  },
];

const Tagline = styled.div`
  font-size: 16px;
  font-weight: 600;
  // font-style: italic;
  // color: ${theme.color.primary.dark};
  color: white;
  line-height: 1.3;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-weight: 800;
  font-style: italic;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 56px;
  // color: ${theme.color.text.dark};
  color: white;
  font-size: 24px;
  // transform: skew(-15deg);
  @media (min-width: 776px) {
    font-size: 36px;
  }
`;

const Description = styled.div`
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 12px;
  // color: ${theme.color.text.normal};
  color: white;
  font-size: 16px;
  @media (min-width: 776px) {
    font-size: 16px;
  }
`;

const Highlight = styled.span`
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 6px;
  padding-right: 6px;
  // background: ${theme.color.primary.dark};
  background-color: rgba(255, 255, 255, 0.9);
  // border-radius: 2px;
  font-weight: 500;
  color: ${theme.color.text.dark};
  // color: ${theme.color.primary.dark};
  white-space: nowrap;
`;

const BannerGradient = styled.div`
  position: absolute;
  top: 0px;
  bottom: 50px;
  z-index: -1;
  width: 100vw;
  background: linear-gradient(
    110deg, 
    #0069FF 0%,
    #FFFFFF 50%,
    #FF50A6 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 500%;
  animation: background 10s linear alternate infinite;
  @keyframes background {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
  }
`;

const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: calc(96px - 12px);
  width: 100%;
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ToggleButton = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 50px;
  color: ${props => props.selected ? "white" : theme.color.text.dark};
  background-color: ${props => props.selected ? theme.color.primary.dark : theme.color.background};
  // border: 1px solid ${props => props.selected ? theme.color.primary.dark : theme.color.line};
  height: 44px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
  width: 156px;
  text-align: center;
  &:hover {
    background-color: ${props => props.selected ? theme.color.primary.dark_hover : theme.color.background};
    // border: 1px solid ${props => props.selected ? theme.color.primary.dark_hover : theme.color.line};
  }
`;

const ToggleCaption = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  padding-top: 12px;
  color: ${theme.color.primary.dark};
  text-align: center;
  padding-left: 178px;
  margin-bottom: 48px;
`;

const CardWrapper = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  transform: scale(1);
  transition: 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`;

const Card = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${theme.color.caption};
  background-color: ${theme.color.background};
  border-radius: 16px;
  padding-top: 48px;
  padding-left: 24px;
  padding-right: 24px;
  width: 336px;
  height: 740px;
  transform: scale(1);
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  & hover: {
    transform: scale(1.03);
    // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
`;

const ProductName = styled.div`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: calc(24px - 4px);
  line-height: 1;
  color: ${theme.color.text.dark};
`;

const ProductPrice = styled.div`
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 24px;
  line-height: 1;
  color: ${theme.color.text.dark};
`;

const ProductPriceText = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: ${theme.color.paragraph};
`;

const FeatureList = styled.div`
  // margin-bottom: 50px;
`;

const ProductFeature = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
`;

const FeatureText = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 0px;
  line-height: 1.3;
  color: ${theme.color.paragraph};
`;

const FeatureIcon = styled.img`
  height: 18px;
  margin-right: 12px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 48px;
`;

const Footnote = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 0px;
  line-height: 1.3;
  color: ${theme.color.text.light};
  width: 100%;
`;

const Bold = styled.span`
  font-weight: 600;
  color: ${theme.color.text.dark};
`;

const Italic = styled.span`
  font-style: italic;
`;

const SubscribePage = (props) => {

  const { API, articleData, userData } = props;

  // let [prices, setPrices] = React.useState();

  let [yearly, setYearly] = React.useState(false);

  const navigate = useNavigate();

  const goToCheckout = (price_id, userData) => {
    if (userData) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        withCredentials: true,
        data: {
          "priceId": price_id
        }
      }
      Axios.post(API + "/payments/create-checkout-session", config)
        .then(response => {
          window.location.href = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      navigate("/sign-up")
    }
  }

  const Feature = (props) => {
    return (
      <ProductFeature>
        <FeatureIcon src="/small-icons/circle-check-outlined.svg" />
        <FeatureText>
          {props.children}
        </FeatureText>
      </ProductFeature>
    )
  }

  const ProductCard = (props) => {

    const { name, priceId, price, yearly } = props;

    const card =
      <CardWrapper>
        <Card>
          <ProductName>
            {name}
          </ProductName>
          <ProductPrice>
            {price}<ProductPriceText> / {yearly ? "year" : "month"}</ProductPriceText>
          </ProductPrice>
          <ButtonWrapper>
            <Button style="outlined" corners="pill" label="Subscribe" onClick={() => goToCheckout(priceId, userData)} />
          </ButtonWrapper>
          <FeatureList>
            {props.children}
          </FeatureList>
        </Card>
      </CardWrapper>

    return card;
  }

  return (
    <PageWrapper>
      <BannerWrapperTitle>
        <TitleWrapper>
          <Tagline>
            Be the smartest person in the pub
          </Tagline>
          <Title>
            Clear, intuitive insights
          </Title>
          <Description>
            Advanced football analytics have revolutionized how football is played and analyzed. With our <Highlight>intuitive data visualization</Highlight>, you get all the game-changing insights without all the numbers. Subscribe today for as little as <Highlight>$2 a month!</Highlight>
          </Description>
        </TitleWrapper>
      </BannerWrapperTitle>
      <PricingWrapper>
        <ToggleWrapper>
          <ToggleButton selected={!yearly} onClick={() => setYearly(false)}>
            Monthly
          </ToggleButton>
          <ToggleButton selected={yearly} onClick={() => setYearly(true)}>
            Yearly
          </ToggleButton>
        </ToggleWrapper>
        <ToggleCaption>
          Save 17%!
        </ToggleCaption>
        <GridWrapper>
          <ProductCard data={data[0]}
            name={data[0].name}
            price={yearly ? data[0].price_yearly : data[0].price_monthly}
            yearly={yearly}
            priceId={yearly ? data[0].price_id_yearly : data[0].price_id_monthly}
          >
            {/* <Feature>
              Data journalism covering the world's most popular leagues — the Premier League, La Liga, the Bundesliga, Serie A, Ligue 1, and MLS
            </Feature> */}
            <Feature>
              <Bold>Match predictions:</Bold> industry-leading AI-powered score predictions for upcoming matches
            </Feature>
            <Feature>
              <Bold>League predictions:</Bold> AI-powered league simulations, predicted final table, title and relegation predictions
            </Feature>
            <Feature>
              <Bold>Fixture guides:</Bold> upcoming fixtures, recommended matches, upset watch, expected competitiveness, and more
            </Feature>
            {/* <Feature>
              <Bold>Match reports:</Bold> match timeline, prediction evaluations, match quality ratings, and more<Italic>(1/match)</Italic>
            </Feature> */}
            <Feature>
              Selected match previews and match reports from <Bold>The Insider</Bold> package
            </Feature>
            <Feature>
              7-day free trial
            </Feature>
          </ProductCard>
          <ProductCard data={data[1]}
            name={data[1].name}
            price={yearly ? data[1].price_yearly : data[1].price_monthly}
            yearly={yearly}
            priceId={yearly ? data[1].price_id_yearly : data[1].price_id_monthly}
          >
            <Feature>
              All content from <Bold>The Fan</Bold> package plus our popular match previews, match reports, and power rankings!
            </Feature>
            <Feature>
              <Bold>Match previews:</Bold> AI-powered score predictions, HGIMT team ratings, recent form, fixture histories, and more
            </Feature>
            <Feature>
              <Bold>Match reports:</Bold> timeline, AI-powered HGIMT player ratings, combined XI, attacking threat analysis, player creativity analysis, and more
            </Feature>
            <Feature>
              <Bold>Power rankings:</Bold> AI-powered HGIMT team ratings, power rankings, analysis of week-to-week movements and longer-term trends
            </Feature>
            <Feature>
              Selected content from <Bold>The Analyst</Bold> package
            </Feature>
            <Feature>
              7-day free trial
            </Feature>
          </ProductCard>
          <ProductCard data={data[2]}
            name={data[2].name}
            price={yearly ? data[2].price_yearly : data[2].price_monthly}
            yearly={yearly}
            priceId={yearly ? data[2].price_id_yearly : data[2].price_id_monthly}
          >
            <Feature>
              All content from <Bold>The Insider</Bold> package plus in-depth analyis using intuitive data visualization!
            </Feature>
            {/* <Feature>
              <Bold>Positional analysis:</Bold> a visual analysis of the most in-form attackers, midfielders, defenders, and keepers <Italic>(1/league/week)</Italic>
            </Feature> */}
            {/* <Feature>
              <Bold>Special features:</Bold> in-depth articles with original and innovative quantitative analysis <Italic>(1/month)</Italic>
            </Feature> */}
            <Feature>
              <Bold>Match analysis:</Bold> an visual analysis of each team's tactics in a recent match
            </Feature>
            <Feature>
              <Bold>Team analysis:</Bold> a visual analysis of a team's recent performances — formations, possession, passing, pressing, counter-attacking, set-pieces, and more
            </Feature>
            <Feature>
              <Bold>Player analysis:</Bold> a visual analysis of a player's recent performances — shot and assist quality, passing quality and creativity, tackles and blocks, dicipline, and more
            </Feature>
            <Feature>
              7-day free trial
            </Feature>
            {/* <Feature>
              <Bold>Clinical shooting analysis:</Bold> analysis of best offensive performances, shot efficiency viz, xG contributed viz, and more (weekly per league)
            </Feature>
            <Feature>
              <Bold>Critical intervention analysis:</Bold> analysis of best defensive performances, blocks viz, tackles viz, and more (weekly per league)
            </Feature>
            <Feature>
              <Bold>Shot-stopping analysis:</Bold> analysis of best goalkeeping performances, xGOT vs goals conceded viz, and more (weekly per league)
            </Feature>
            <Feature>
              <Bold>Creativity analysis:</Bold> analysis of most creative players, xAG viz, progressive passes viz, shot creating actions (SCA) viz, and more (weekly per league)
            </Feature>
            <Feature>
              <Bold>Team tactical analysis:</Bold> analysis of a team's tactics, ball posession viz, passing efficiency viz, set piece dependency viz, home field advantage viz, and more (2 teams per league per week)
            </Feature> */}
          </ProductCard>
        </GridWrapper>
        {/* <GridWrapper>
          <Footnote>
            We use Stripe to securely process payments. We never see or handle your payment information. This site is owned by Hjartdal Studios LLC, an app development studio.
          </Footnote>
        </GridWrapper> */}
      </PricingWrapper>
      <Section
        articleData={articleData}
        userData={userData}
        title="Sample articles"
        text="Want to take a closer look before you subscribe? Here's a selection of sample articles from each subscription tier. See what you like before you subscribe! You also get a 7-day free trial, so there's no downside."
        tag="See what it's all about"
        count={9}
      />
      {/* <Dialog open={true} text="Your order has been canceled." onConfirm={() => {}} onCancel={() => {}} /> */}
    </PageWrapper>
  );
};

export default SubscribePage;
