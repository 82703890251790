import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FooterWrapper } from "./wrapper-elements";
import theme from "./theme.json";

const LogoWrapper = styled(Link)`
  height: 48px;
  margin-bottom: 48px;
  @media (min-width: 888px) {
    height: 60px;
  }
`;

const Logo = styled.img`
  display: block;
  height: 100%;
`;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  // height: 64px;
  margin-top: 24px;
  color: rgba(255, 255, 255, 0.3);
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ItemColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-left: 48px;
  padding-right: 48px;
  width: 200px;
`;

const ItemHeading = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 8px;
  color: white;
  white-space: nowrap;
  line-height: 1;
`;

const Item = styled(Link)`
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  // border-left: 1px solid ${theme.color.line.normal};
  // padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  line-height: 1;
  &:hover{
    // padding-left: 14px;
    color: rgba(255, 255, 255, 0.6);
    // color: ${theme.color.neutral.dark};
    // border-left: 3px solid ${theme.color.primary.dark};
  }
`;

const Footer = (props) => {

  return (
    <FooterWrapper>
      <LogoWrapper to="/">
        <Logo src="HGIMT-logo-white.svg" />
      </LogoWrapper>
      <ItemWrapper>
        <ItemColumn>
          <ItemHeading>
            Coverage
          </ItemHeading>
          <Item to="/match-predictions">
            Match predictions
          </Item>
          <Item to="/league-predictions">
            League predictions
          </Item>
          <Item to="/fixture-guides">
            Fixture guides
          </Item>
          <Item to="/match-previews">
            Match previews
          </Item>
          <Item to="/match-reports">
            Match reports
          </Item>
          <Item to="/power-rankings">
            Power rankings
          </Item>
          <Item to="/match-analysis">
            Match analysis
          </Item>
          <Item to="/team-analysis">
            Team analysis
          </Item>
          <Item to="/player-analysis">
            Player analysis
          </Item>
        </ItemColumn>
        <ItemColumn>
          <ItemHeading>
            Leagues
          </ItemHeading>
          <Item to="/premier-league">
            Premier League
          </Item>
          <Item to="/la-liga">
            La Liga
          </Item>
          <Item to="/bundesliga">
            Bundesliga
          </Item>
          <Item to="/serie-a">
            Serie A
          </Item>
          <Item to="/ligue-1">
            Ligue 1
          </Item>
          {/* <Item to="/mls">
            MLS
          </Item> */}
        </ItemColumn>
        <ItemColumn>
          <ItemHeading>
            Product
          </ItemHeading>
          <Item to="/subscribe">
            Subscribe
          </Item>
          <Item to="/sign-up">
            Sign up
          </Item>
          <Item to="/sign-in">
            Sign in
          </Item>
        </ItemColumn>
        <ItemColumn>
          <ItemHeading>
            Support
          </ItemHeading>
          <Item to="/about">
            About
          </Item>
          <Item to="/terms-of-service">
            Terms of service
          </Item>
          <Item to="/privacy-policy">
            Privacy policy
          </Item>
        </ItemColumn>
      </ItemWrapper>
      <Copyright>
        © 2023 Hjartdal Studios LLC
      </Copyright>
    </FooterWrapper>
  )
}

export default Footer;
