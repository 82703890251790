import React, { useState } from "react";
import styled from "styled-components";
import Button from "./button";
import { PageWrapper, ContentWrapper } from "./wrapper-elements"
import { Heading } from "./text-elements"
import { FormGrid, FormInstructions, FormElement } from "./form-elements";

const SignUpPage = (props) => {

  return (
    <PageWrapper $paddingTop>
      <ContentWrapper>
        <FormGrid>
          <Heading>
            Almost done!
          </Heading>
          <FormInstructions>
            We just sent you an email asking you to confirm your email address. Please click the link in the email to activate your account.
          </FormInstructions>
          <FormElement>
            <Button style="solid" corners="rounded" label="Dismiss" to="/" />
          </FormElement>
        </FormGrid>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default SignUpPage;
