import React from "react";
import { PageWrapper } from "./wrapper-elements";
import { Heading } from "./text-elements";
import { FormGrid, FormInstructions } from "./form-elements";
import SignInForm from "./sign-in-form";

const WelcomePage = (props) => {

  const { API, saveUserData } = props;

  return (
    <PageWrapper $paddingTop>
        <FormGrid>
          <Heading>
            Welcome!
          </Heading>
          <FormInstructions>
            Thanks for verifying your email adresss. Your account is now active! Please sign in to continue.
          </FormInstructions>
        </FormGrid>
      <SignInForm API={API} saveUserData={saveUserData} to="/subscribe" />
    </PageWrapper>
  )
}

export default WelcomePage;
