import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper, ContentWrapper } from "./wrapper-elements.js"
import theme from "./theme";

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3;
  // color: ${theme.color.heading};
  color: white;
  text-align: left;
  margin-bottom: 48px;
  font-size: 24px;
  text-align: ${props => props.$centered ? "center" : "left"};
  &::before {
    content: '';
    display: block;
    margin-top: -10px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -10px;
  }
  @media (min-width: 888px) {
    font-size: 42px;
    margin-bottom: 72px;
  };
`;

const Author = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 600;
  // color: ${theme.color.heading};
  color: white;
  margin-bottom: 12px;
  line-height: 1.3;
`;

const Date = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  // color: ${theme.color.caption};
  color: white;
  opacity: 0.5;
`;

const Caption = styled.div`
  font-size: 16px;
  font-weight: 600;
  // color: ${theme.color.primary.dark};
  color: white;
  line-height: 1.3;
  margin-bottom: 24px;
`;

const Heading = styled.h2`
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  color: ${props => props.$inverted?  "white" : theme.color.heading};
  margin-top: 48px;
  margin-bottom: 16px;
  text-align: ${props => props.$centered ? "center" : "left"};
  &::before {
    content: '';
    display: block;
    margin-top: -6px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
  @media (min-width: 888px) {
    font-size: 32px;
    margin-top: 48px;
    margin-bottom: 16px;
  };
`;

const SectionTitle = styled(Heading)`
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 16px;
  @media (min-width: 888px) {
    margin-top: 0px;
    margin-bottom: 16px;
  };
`;

const Paragraph = styled.p`
  width: 100%;
  font-weight: 300;
  line-height: 1.5;
  color: ${props => props.$inverted? "white" : theme.color.paragraph};
  font-size: 16px;
  text-align: ${props => props.$centered ? "center" : "left"};
  margin-top: 16px;
  margin-bottom: 16px;
  &::before {
    content: '';
    display: block;
    margin-top: -6px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
`;

const Highlight = styled.span`
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 6px;
  padding-right: 6px;
  background: ${theme.color.primary.dark};
  font-weight: 500;
  color: white;
  white-space: nowrap;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  color: ${theme.color.paragraph};
  margin-bottom: 12px;
  height: 64px;
`;

const CardDate = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: ${theme.color.caption};
`;

const CardType = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: ${theme.color.caption};
`;

const CardTier = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: ${theme.color.heading};
`;

const TextLink = styled(Link)`
  font-weight: 600;
  color: ${theme.color.text.dark};
  padding-bottom: 1px;
  &:hover {
    border-bottom: 1.5px solid ${theme.color.text.dark};
  }
`;

export { Title, SectionTitle, Heading, Paragraph, Author, Date, Caption, Highlight, TextLink };
