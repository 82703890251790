import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ArticleCard from "./article-card-2";
import Button from "./button";
import { GridWrapper, TitleWrapper } from "./wrapper-elements";
import { SectionTitle } from "./text-elements";
import theme from "./theme.json";

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 48px;
  overflow: visible;
  @media (min-width: 888px) {
    margin-bottom: calc(96px - 12px);
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Section = (props) => {

  const { articleData, userData, title, buttonLabel, buttonLink, count } = props;

  const { width } = useWindowDimensions();

  let selectedData = articleData.slice(0, count);

  if (count && width < 888) {
    selectedData = articleData.slice(0, 4);
  }

  if (count && width >= 888 && width < 1264) {
    if (count % 2 === 0) {
      selectedData = articleData.slice(0, count);
    } else {
      selectedData = articleData.slice(0, count - 1);
    }
  }

  if (count && width >= 1264) {
    selectedData = articleData.slice(0, count);
  }

  let moreButton;
  if (buttonLabel) {
    moreButton =
      <ButtonWrapper>
        <Button style="outlined" corners="pill" label="See more" to={buttonLink} />
      </ButtonWrapper>
  }

  let titleComponent;
  if (title) {
    titleComponent =
      <TitleWrapper>
        <SectionTitle>
          {title}
        </SectionTitle>
      </TitleWrapper>
  }

  return (
    <Wrapper>
      {/* <Feature
        icon={icon}
        tag={tag}
        title={title}
        text={text}
      /> */}
      {titleComponent}
      <GridWrapper>
          {selectedData.map((item, i) => {
            return (
              <ArticleCard key={i} articleData={item} userData={userData} />
            )
          })}
        </GridWrapper>
      {moreButton}
    </Wrapper>
  )
}

export default Section;
