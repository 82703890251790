import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import TextBox from "./text-box";
import Button from "./button";
import { FormGrid, FormElement, FormInstructions, FormMessage } from "./form-elements"
import { TextLink } from "./text-elements"
import theme from "./theme";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 96px;
`;

const Text = styled.p`
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 48px;
  color: ${theme.color.text.dark};
  width: 340px;
  font-size: 24px;
  @media (min-width: 776px) {
    width: 696px;
    font-size: 32px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
`;

const Message = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.color.secondary.dark};
  border-radius: 8px;
  background-color: ${theme.color.secondary.light};
  border: 1.5px solid ${theme.color.secondary.dark};
  line-height: 1.3;
`;

const Tag = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 1.6;
  color: ${theme.color.text.medium};
  margin-bottom: 48px;
  text-align: center;
  max-width: 340px;
`;

const SignUpForm = (props) => {

  const { API, saveUserData } = props;

  let [email, setEmail] = useState("");
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let [passwordCheck, setPasswordCheck] = useState("");
  let [message, setMessage] = useState("");

  const navigate = useNavigate();

  const signUp = () => {
    if (password === passwordCheck) {
      setMessage("");
      Axios({
        method: "post",
        url: API + "/account/sign-up",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          "email": email,
          "username": username,
          "password": password,
        }
      })
        .then(response => {
          navigate("/confirmation");
          // setConfirmationOn(true);
          // callback(response.data);
          // setMessage("");
          console.log(response.data);
        })
        .catch(error => {
          console.log(error.response.data.error)
          setMessage(error.response.data.error);
        });
    } else {
      setMessage("The passwords you entered don't match. Please check them and try again.");
    }
  }

  return (
    <FormGrid>
      <FormElement>
        <TextBox type="email" value={email} label="Email" onChange={setEmail} />
      </FormElement>
      {/* <FormElement>
        <TextBox type="text" value={username} label="Username" note="Usernames must have at least 8 characters and can only have letters and numbers." onChange={setUsername} />
      </FormElement> */}
      <FormElement>
        <TextBox type="text" value={password} label="Password" note="Passwords must have at least 8 characters and have at least one lower case letter, one uppercase letter, one number, and one special character." onChange={setPassword} />
      </FormElement>
      <FormElement>
        <TextBox type="text" value={passwordCheck} label="Confirm password" onChange={setPasswordCheck} />
      </FormElement>
      <FormInstructions>
        <span>By signing up, you agree to our <TextLink to="/terms-of-service">Terms of Service</TextLink> and our <TextLink to="/privacy-policy">Privacy Policy</TextLink>.</span>
      </FormInstructions>
      <FormElement>
        <Button style="outlined" corners="pill" label="Sign up" onClick={signUp} />
      </FormElement>
      <FormMessage style={{ "display": message === "" ? "none" : "flex" }}>
        {message}
      </FormMessage>
    </FormGrid>
  )
}

export default SignUpForm;
