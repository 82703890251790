import React from "react";
import styled from "styled-components";
import { useNavigate, Navigate } from "react-router-dom";
import { PageWrapper, ContentWrapper } from "./wrapper-elements";
import { Title, Heading } from "./text-elements";
import { FormGrid, FormElement, FormInstructions, FormRow, FormMessage, FormButtonWrapper } from "./form-elements";
import Button from "./button";
import TextBox from "./text-box";
import theme from "./theme";
import Axios from "axios";

const Item = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${theme.color.paragraph};
  margin-bottom: 32px;
`;

const AccountPage = (props) => {

  const navigate = useNavigate();

  const { API, userData, saveUserData, signOut } = props;

  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordCheck, setNewPasswordCheck] = React.useState("");
  const [newEmail, setNewEmail] = React.useState("");
  const [messageText, setMessageText] = React.useState("");
  const [deleteAccountDialogOn, setDeleteAccountDialogOn] = React.useState(false);
  const [changePasswordDialogOn, setChangePasswordDialogOn] = React.useState(false);
  const [changeEmailDialogOn, setChangeEmailDialogOn] = React.useState(false);

  let subscription = "none";
  if (userData) {
    if (userData.basic) {
      subscription = "The Fan"
    }
    if (userData.standard) {
      subscription = "The Insider"
    }
    if (userData.premium) {
      subscription = "The Analyst"
    }
  }

  const requestToDeleteAccount = () => {
    setDeleteAccountDialogOn(true);
  }

  const requestToChangePassword = () => {
    setChangePasswordDialogOn(true);
  }

  const requestToChangeEmail = () => {
    setChangeEmailDialogOn(true);
  }

  const closeDialog = () => {
    setDeleteAccountDialogOn(false);
    setChangePasswordDialogOn(false);
    setChangeEmailDialogOn(false);
    setPassword("");
    setNewPassword("");
    setNewPasswordCheck("");
    setNewEmail("");
    setMessageText("");
  }

  const deleteAccount = () => {
    Axios({
      method: "post",
      url: API + "/account/delete-account",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "authorization": userData.token,
      },
      data: {
        "password": password,
      }
    })
      .then(response => {
        signOut();
        navigate("/");
        // setMessageText("Your account has been deleted.");
      })
      .catch(error => {
        setMessageText(error.response.data.error);
        console.log(error.response.data.error)
      });
  }

  const changePassword = () => {
    if (newPassword === newPasswordCheck) {
      Axios({
        method: "post",
        url: API + "/account/change-password",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Origin": "*",
          "authorization": userData.token,
        },
        data: {
          "password": password,
          "new_password": newPassword,
        }
      })
        .then(response => {
          console.log(response.data);
          saveUserData(response.data);
          closeDialog();
        })
        .catch(error => {
          setMessageText(error.response.data.error);
          console.log(error.response.data.error)
        });
    } else {
      setMessageText("The passwords don't match.")
    }
  }

  const changeEmail = () => {
    Axios({
      method: "post",
      url: API + "/account/change-email",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "authorization": userData.token,
      },
      data: {
        "new_email": newEmail,
      }
    })
      .then(response => {
        console.log(response.data);
        saveUserData(response.data);
        navigate("/account");
        closeDialog();
      })
      .catch(error => {
        setMessageText(error.response.data.error);
        console.log(error.response.data.error)
      });
  }

  let message;

  if (messageText !== "") {
    message =
      <FormMessage>
        {messageText}
      </FormMessage>
  }

  let page;

  if (userData) {

    page =
      <PageWrapper $paddingTop>
        <ContentWrapper>
          <Title>
            My account
          </Title>
          <Item>
            <b>Email:</b> {userData.email}
          </Item>
          <Item>
            <b>Subscription:</b> {subscription}
          </Item>
        </ContentWrapper>
        <FormRow>
          <FormButtonWrapper>
            <Button style="outlined" corners="rounded" small label="Change email" onClick={() => setChangeEmailDialogOn(true)} />
          </FormButtonWrapper>
          <FormButtonWrapper>
            <Button style="outlined" corners="rounded" small label="Change password" onClick={() => setChangePasswordDialogOn(true)} />
          </FormButtonWrapper>
          {/* <FormButtonWrapper>
            <Button style="outlined" corners="rounded" small label="Manage subscription" />
          </FormButtonWrapper> */}
          <FormButtonWrapper>
            <Button style="solid" corners="rounded" small label="Delete account" onClick={() => setDeleteAccountDialogOn(true)} />
          </FormButtonWrapper>
        </FormRow>
      </PageWrapper>

    if (deleteAccountDialogOn) {
      page =
        <PageWrapper $paddingTop>
          <FormGrid>
            <Heading>
              Delete account
            </Heading>
            <FormInstructions>
              Are you sure you want to delete your account? This can't be undone. If you're sure, please confirm your password.
            </FormInstructions>
            <FormElement>
              <TextBox type="text" label="Password" value={password} onChange={setPassword} />
            </FormElement>
            <FormRow>
              <FormButtonWrapper>
                <Button style="solid" corners="rounded" small label="Delete account" onClick={deleteAccount} />
              </FormButtonWrapper>
              <FormButtonWrapper>
                <Button style="outlined" corners="rounded" small label="Cancel" onClick={closeDialog} />
              </FormButtonWrapper>
            </FormRow>
            {message}
          </FormGrid>
        </PageWrapper>
    }

    if (changePasswordDialogOn) {
      page =
        <PageWrapper $paddingTop>
          <FormGrid>
            <Heading>
              Change password
            </Heading>
            <FormInstructions>
              Please enter your current password and enter and confirm a new password.
            </FormInstructions>
            <FormElement>
              <TextBox type="text" label="Current password" value={password} onChange={setPassword} />
            </FormElement>
            <FormElement>
              <TextBox type="text" label="New password" note="Passwords must have at least 8 characters and have at least one lower case letter, one uppercase letter, one number, and one special character." value={newPassword} onChange={setNewPassword} />
            </FormElement>
            <FormElement>
              <TextBox type="text" label="Confirm new password" value={newPasswordCheck} onChange={setNewPasswordCheck} />
            </FormElement>
            <FormRow>
              <FormButtonWrapper>
                <Button style="solid" corners="rounded" small label="Change password" onClick={changePassword} />
              </FormButtonWrapper>
              <FormButtonWrapper>
                <Button style="outlined" corners="rounded" small label="Cancel" onClick={closeDialog} />
              </FormButtonWrapper>
            </FormRow>
            {message}
          </FormGrid>
        </PageWrapper>
    }

    if (changeEmailDialogOn) {
      page =
        <PageWrapper $paddingTop>
          <FormGrid>
            <Heading>
              Change email
            </Heading>
            <FormInstructions>
              Please enter a new email address. You will recieve an email with a link to verify the new address. 
            </FormInstructions>
            <FormElement>
              <TextBox type="text" label="New email" value={newEmail} onChange={setNewEmail} />
            </FormElement>
            <FormRow>
              <FormButtonWrapper>
                <Button style="solid" corners="rounded" small label="Change email" onClick={changeEmail} />
              </FormButtonWrapper>
              <FormButtonWrapper>
                <Button style="outlined" corners="rounded" small label="Cancel" onClick={closeDialog} />
              </FormButtonWrapper>
            </FormRow>
            {message}
          </FormGrid>
        </PageWrapper>
    }

  } else {
    page =
      <Navigate replace to="/sign-in" />
  }

  return page
}

export default AccountPage;
