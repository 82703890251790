import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper, BannerWrapperTitle, ArticleWrapper, ImageWrapper, TitleWrapper } from "./wrapper-elements";
import { Title, Heading, Paragraph, Author, Date, Caption } from "./text-elements";
import Section from "./section";
import Feature from "./feature";
import Axios from "axios";
// import FadeIn from "react-fade-in";

import theme from "./theme.json";

const Image = styled.img`
  position: relative;
  display: block;
  width: 100%;
`;

const FadeIn = styled.div`
  @keyframes fadeIn {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  animation-name: fadeIn;
  animation-duration: 1.5s;
`;

const ArticlePage = (props) => {

  const { userData, API, articleId, articleMetadata, articleData } = props;

  const [data, setData] = React.useState();
  const [message, setMessage] = React.useState();

  const getData = () => {
    let headers;
    if (userData) {
      headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        'authorization': userData.token,
      }
    } else {
      headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    }
    Axios({
      method: "get",
      url: API + "/content/article-data/" + articleId,
      headers: headers
    })
      .then(response => {
        setMessage("");
        console.log(response)
        setData(response.data);
      })
      .catch(error => {
        if (error.response) {
          // setMessage(error.response.data.error);
        }
      });
  }

  useEffect(() => {
    getData();
  }, [])

  let banner =
    <BannerWrapperTitle>
      <TitleWrapper>
        <Caption>
          {articleMetadata.type}
        </Caption>
        <Title>
          {articleMetadata.title}
        </Title>
        <Author>
          By {articleMetadata.author}
        </Author>
        <Date>
          {articleMetadata.date}
        </Date>
      </TitleWrapper>
    </BannerWrapperTitle>

  let content;
  if (data) {

    content =
      <PageWrapper>
        {banner}
        <FadeIn>
          <ArticleWrapper>
            {data.content.map((item, i) => {
              if (item.type === "heading") {
                return (
                  <Heading key={i}>
                    {item.text}
                  </Heading>
                )
              } else if (item.type === "paragraph") {
                return (
                  <Paragraph key={i}>
                    {item.text}
                  </Paragraph>
                )
              } else if (item.type === "image") {
                return (
                  <ImageWrapper key={i}>
                    <Image src={item.file} />
                  </ImageWrapper>
                )
              }
            })}
          </ArticleWrapper>
        </FadeIn>
        <Section
          articleData={articleData}
          userData={userData}
          title="Recommended articles"
          count={6}
        />
      </PageWrapper>
  } else {
    content =
      <PageWrapper>
        {banner}
        <FadeIn>
          <ArticleWrapper style={{ marginBottom: "96px"}}>
            <Feature
              title="Subscribe today!"
              text="Sorry, this article is only available to members who have a subscription. Subscribe today to enjoy our industry-leading, independent football data journalism!"
              buttonLabel="Subscribe"
              buttonLink="/subscribe"
            />
          </ArticleWrapper>
        </FadeIn>
      </PageWrapper>
  }

  return (
    content
  )
};

export default ArticlePage;
