import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Chip from "./chip";
import theme from "./theme.json";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  width: 360px;
`;

const GridCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  transform: scale(1);
  transition: 0.2s ease-in-out;
  ${Wrapper}:hover & {
    transform: scale(1.02);
  }
`;

const ImageWrapper = styled(Link)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  aspect-ratio: 1.4;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${theme.color.background};
  // border: 1px solid ${theme.color.line.normal};
  // ${Wrapper}:hover & {
  //   border: 1px solid ${theme.color.line.hover};
  // }
`;

const Image = styled.img`
  width: 75%;
  // transform: scale(1);
  // transition: 0.15s ease-in-out;
  // ${Wrapper}:hover & {
  //   transform: scale(1.075);
  // }
`;

const TextWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Date = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  color: ${theme.color.text.medium};
`;

const Title = styled.div`
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  color: ${theme.color.text.dark};
  transition: 0.2s ease-in-out;
  ${Wrapper}:hover & {
    color: ${theme.color.primary.dark};
  }
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
  overflow: hidden;
`;

const Lock = styled.img`
  margin-right: 8px;
  height: 10px;
`;

const ArticleCard = (props) => {

  const { articleData, userData } = props;

  let unlocked = false;

  if (articleData.free_sample === 1) {
    unlocked = true;
  }

  if (userData) {
    if (articleData.tier === "Fan" && (userData.basic || userData.standard || userData.premium)) {
      unlocked = true;
    }
    if (articleData.tier === "Insider" && (userData.standard || userData.premium)) {
      unlocked = true;
    }
    if (articleData.tier === "Analyst" && userData.premium) {
      unlocked = true;
    }
  }

  let tier =
    <Chip to="/subscribe">
      <Lock src="/small-icons/locked.svg" />{articleData.tier}
    </Chip>

  if (unlocked) {
    tier =
      <Chip to="/subscribe">
        {articleData.tier}
      </Chip>
  }

  if (articleData.free_sample) {
    tier =
    <Chip to="/subscribe">
      Free
    </Chip>
  }

  return (
    <Wrapper>
      <GridCard>
        <ImageWrapper to={articleData.link}>
          <Image src="/example-cover-image.png" />
        </ImageWrapper>
        <ChipWrapper>
          <Chip to={articleData.league_url}>
            {articleData.league}
          </Chip>
          <Chip to={articleData.type_url}>
            {articleData.type}
          </Chip>
          {tier}
        </ChipWrapper>
        <TextWrapper to={articleData.link}>
          <Title>
            {articleData.title}
          </Title>
        </TextWrapper>
      </GridCard>
    </Wrapper>
  )
}

export default ArticleCard;
