import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import TextBox from "./text-box";
import Button from "./button";
import { TextLink } from "./text-elements.js";
import { FormGrid, FormElement, FormMessage, FormInstructions } from "./form-elements.js";
import theme from "./theme";

const SignInForm = (props) => {

  const { API, saveUserData, to } = props;

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [message, setMessage] = useState("");

  const navigate = useNavigate();

  const logIn = () => {
    Axios({
      method: "post",
      url: API + "/account/sign-in",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "email": email,
        "password": password,
      }
    })
      .then(response => {
        setMessage("");
        saveUserData(response.data);
        navigate(to);
      })
      .catch(error => {
        if (error.response) {
          setMessage(error.response.data.error);
        }
      });
  }

  return (
    <FormGrid>
      <FormElement>
        <TextBox type="text" value={email} label="Email" onChange={setEmail} />
      </FormElement>
      <FormElement>
        <TextBox type="text" value={password} label="Password" onChange={setPassword} />
      </FormElement>
      <FormElement>
        <Button style="outlined" corners="pill" label="Sign in" onClick={logIn} />
      </FormElement>
      <FormMessage style={{ "display": message === "" ? "none" : "flex" }}>
        {message}
      </FormMessage>
    </FormGrid>
  )
}

export default SignInForm;
