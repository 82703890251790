import styled from "styled-components";
import theme from "./theme.json";

const PageWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  left: 0px;
  min-height: calc(100vh - 80px);
  // padding-top: ${props => props.$paddingTop ? "96px" : "0px"};
  // padding-bottom: 96px;
  // background-color: rgba(250, 250, 250, 1);
  @media (min-width: 888px) {
    padding-left: 96px;
    // left: 100px;
  }
`;

// const Page = styled.div`
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: flex-start;
//   padding-left: 0px;
//   width: 100%;
//   @media (min-width: 900px) {
//     padding-left: 100px;
//   }
// `;

const GridWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: calc((100% - 360px) / 2);
  padding-right: calc((100% - 360px) / 2);
  @media (min-width: 888px) {
    flex-wrap: wrap;
    width: 720px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: unset;
  }
  @media (min-width: 1248px) {
    width: 1080px;
  }
  &::-webkit-scrollbar { 
    display: none;
  }
`;

const FeatureWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 372px;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 888px) {
    width: 696px;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: 1248px) {
    width: 1056px;
    padding-right: calc(336px + 24px);
  }
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 372px;
  @media (min-width: 888px) {
    width: 744px;
  }
  // @media (min-width: 1248px) {
  //   width: 1116px;
  // }
`;

const ArticleWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 36px;
  width: 372px;
  @media (min-width: 888px) {
    width: 720px;
    // padding-left: 48px;
    // padding-right: 48px;
    // margin-top: calc(96px - 36px);
    margin-top: -48px;
    margin-bottom: calc(96px - 16px);
  }
`;

const BannerWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 144px;
  padding-bottom: 48px;  
  margin-bottom: 48px;
  overflow: hidden;
  @media (min-width: 888px) {
    padding-top: calc(48px * 3);
    padding-bottom: calc(48px * 3);
    padding-left: 96px;
    padding-right: 96px;
    margin-bottom: 96px;
  }
`;

const BannerWrapperTitle = styled(BannerWrapper)`
  background: repeating-linear-gradient(
    45deg,
    rgba(40, 40, 45, 1) 0% 30%,
    rgba(45, 45, 50, 1) 70% 75%,
    rgba(40, 40, 45, 1) 75% 77.5%,
    rgba(45, 45, 50, 1) 77.5% 82.5%,
    rgba(40, 40, 45, 1) 82.5% 85%,
    rgba(45, 45, 50, 1) 85% 90%,
    rgba(40, 40, 45, 1) 90% 92.5%,
    rgba(45, 45, 50, 1) 92.5% 100%
  );
  @media (min-width: 888px) {
    padding-top: calc(48px * 6);
    padding-bottom: calc(48px * 3);
    padding-left: 96px;
    padding-right: 96px;
  }
`;

const BannerWrapperLight = styled(BannerWrapper)`
  // background-color: ${theme.color.background};
  background-color: white;
`;

const BannerWrapperDark = styled(BannerWrapper)`
  background: repeating-linear-gradient(
    45deg,
    rgba(40, 40, 45, 1) 0% 30%,
    rgba(45, 45, 50, 1) 77.5% 82.5%,
    rgba(40, 40, 45, 1) 82.5% 85%,
    rgba(45, 45, 50, 1) 85% 90%,
    rgba(40, 40, 45, 1) 90% 92.5%,
    rgba(45, 45, 50, 1) 92.5% 100%
  );
`;

const TitleWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 336px;
  @media (min-width: 888px) {
    width: 696px;
  }  
  @media (min-width: 1248px) {
    width: 1056px;
    padding-right: calc(336px + 24px);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.color.background};
  border: 2px solid rgba(247, 247, 249, 1);
  margin-bottom: 64px;
  border-radius: 16px;
  width: 336px;
  max-width: 1056px;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  @media (min-width: 888px) {
    width: calc(100%);
    margin-top: 16px;
    margin-bottom: 16px;
  }
  @media (min-width: 1248px) {
    width: 1056px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const FooterWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.color.background_dark};
  padding-top: 48px;
  padding-bottom: 24px;
  width: 100%;
  @media (min-width: 888px) {
    padding-left: 96px;
  }
`;

const HeaderWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding-top: 24px;
  overflow: hidden;
  display: none;
  @media (min-width: 888px) {
    display: flex;
    justify-content: space-between;
    padding-top: 96px;
    padding-right: 96px;
    padding-left: calc(96px * 2);
  }
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 96px;
  width: 340px;
`;

const FormElementWrapper = styled.div`
  margin-bottom: 24px;
`;

const MessageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.color.secondary.dark};
  border-radius: 8px;
  background-color: ${theme.color.secondary.light};
  border: 1.5px solid ${theme.color.secondary.dark};
  line-height: 1.3;
`;

export { PageWrapper, GridWrapper, ContentWrapper, FeatureWrapper, TitleWrapper, BannerWrapperTitle, BannerWrapperDark, BannerWrapperLight, ArticleWrapper, ImageWrapper, HeaderWrapper, FooterWrapper, FormElementWrapper, FormWrapper, MessageWrapper }; 
