import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import TextBox from "./text-box";
import Button from "./button";
import { PageWrapper, ContentWrapper } from "./wrapper-elements"
import { Title, TextLink } from "./text-elements"
import { FormInstructions } from "./form-elements";
import SignUpForm from "./sign-up-form";
import theme from "./theme";

const SignUpPage = (props) => {

  const { API, saveUserData } = props;

  return (
    <PageWrapper $paddingTop>
      <ContentWrapper>
        <Title $centered >
          Sign up for free to access featured articles each week!
        </Title>
        <FormInstructions>
          <span>Already have an account? <TextLink to="/sign-in">Sign in</TextLink></span>
          <span> to enjoy our football data journalism!</span>
        </FormInstructions>
        <SignUpForm API={API} saveUserData={saveUserData} />
      </ContentWrapper>
    </PageWrapper>
  )
}

export default SignUpPage;
