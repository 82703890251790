import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";

// pages
import HomePage from "./home-page";
import SubscribePage from "./subscribe-page";
import SignUpPage from "./sign-up-page";
import SignInPage from "./sign-in-page";
import WelcomePage from "./welcome-page";
import ConfirmationPage from "./confirmation-page";
import AccountPage from "./account-page";
import ArticlePage from "./article-page";
import ArticlesPage from "./articles-page";
import AboutPage from "./about-page";
import PrivacyPolicyPage from "./privacy-policy-page";
import TermsOfServicePage from "./terms-of-service-page";
import Header from "./header";
import Footer from "./footer";
import TextBox from "./text-box";
import Button from "./button";
import SideMenu from "./side-menu"

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const UnlockPage = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30vh;
`;

const FormWrapper = styled.div`
  margin-bottom: 32px;
`;

const Logo = styled.img`
  height: 100px;
  margin-bottom: 100px;
`;

// const API = "http://localhost:4105";
const API = "https://www.api.howgoodismyteam.com";

export default function App() {

  const [articleData, setArticleData] = React.useState(undefined);
  const [password, setPassword] = React.useState("");
  const [unlocked, setUnlocked] = React.useState(false);
  const [userData, setUserData] = React.useState(JSON.parse(sessionStorage.getItem("user")));

  const getArticleData = () => {
    Axios.get(API + "/content/article-metadata")
      .then(response => {
        console.log(response);
        setArticleData(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const navigate = useNavigate();

  const location = useLocation();

  const saveUserData = (userData) => {
    sessionStorage.setItem("user", JSON.stringify(userData));
    setUserData(userData);
  };

  const signOut = () => {
    sessionStorage.removeItem("user");
    setUserData(undefined);
    navigate("/");
  }

  useEffect(() => {
    getArticleData();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  const unlock = () => {
    Axios({
      method: "post",
      url: API + "/account/unlock",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "password": password,
      }
    })
      .then(response => {
        setUnlocked(true);
      })
      .catch(error => {
        console.log(error.response.data.error)
      });
  }

  let app;
  if (articleData && true) {

    app =
      <AppWrapper>
        <Header userData={userData} signOut={signOut} />
        <Routes>
          <Route path="/" exact element={<HomePage articleData={articleData} userData={userData} />} />
          <Route path="/subscribe" exact element={<SubscribePage API={API} articleData={articleData} userData={userData} />} />
          <Route path="/sign-up" exact element={<SignUpPage API={API} saveUserData={saveUserData} />} />
          <Route path="/sign-in" exact element={<SignInPage API={API} saveUserData={saveUserData} />} />
          <Route path="/confirmation" exact element={<ConfirmationPage />} />
          <Route path="/welcome" exact element={<WelcomePage API={API} saveUserData={saveUserData} />} />
          <Route path="/account" exact element={<AccountPage API={API} userData={userData} saveUserData={saveUserData} signOut={signOut} />} />
          <Route path="/about" exact element={<AboutPage />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-service" exact element={<TermsOfServicePage />} />
          <Route
            path="/match-predictions"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.type === "Match predictions";
                  })
                }
                userData={userData}
                tagline="Fingers crossed..."
                title="Match predictions"
                description="We use an advanced machine learning model to predict the results and scorelines of upcoming football matches with industry-leading accuracy. But we also go a step further — we estimate the probability of every possible scoreline so we can tell how unexpected the actual result is. We publish predictions every Thursday for the next week's fixtures."
              />
            }
          />
          <Route
            path="/league-predictions"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.type === "League predictions";
                  })
                }
                userData={userData}
                tagline="Sometimes there's nowhere to go but up. Unless there's relegation..."
                title="League predictions"
                description="We use an advanced machine learning model and Monte Carlo simulations to predict the end-of-season league table. Every week, we estimate the probability of every team finishing in every position in the table. Then, we predict who's going to be relegated, and who's going to win the title. We publish updated predictions every Tuesday."
              />
            }
          />
          <Route
            path="/fixture-guides"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.type === "Fixture guide";
                  })
                }
                userData={userData}
                tagline="You probably know the ones to avoid, but just in case..."
                title="Fixture guides"
                description="There's a lot of football these days. It can be hard to keep up with, and you don't want to waste your time on boring matches. In our fixture guides, we use an advanced machine learning model to estimate the likely quality and competitieness of upcoming matches. We publish fixture guides every Wednesday for the next week's fixtures."
              />
            }
          />
          <Route
            path="/match-previews"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.type === "Match preview";
                  })
                }
                userData={userData}
                tagline="Let's see if your [choose one: optimism, pessimism] is reasonable..."
                title="Match previews"
                description="Our match reports prepare you for what to expect in each match. We use an advanced machine learning model to predict the result and scoreline, compare the teams' current HGIMT ratings, and look at the fixture history, all using intuitive data visualization. We publish match previews two days before each match."
              />
            }
          />
          <Route
            path="/match-reports"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.type === "Match report";
                  })
                }
                userData={userData}
                tagline="You can't catch 'em all..."
                title="Match reports"
                description="Stay up to speed on all the latest matches with our match reports. We visualize the key events in the game, grade our scoreline prediction, use our prediction model to assess how surprising the scoreline is, and grade the entertainment value of the match. We publish match reports the day after each match."
              />
            }
          />
          <Route
            path="/power-rankings"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.type === "Power rankings";
                  })
                }
                userData={userData}
                tagline="Why'd you put [my favorite team] below [their biggest rival]?!"
                title="Power rankings"
                description="People love power rankings. Or arguing with them at least. We use an advanced machine learning model to estimate ratings for every team that change week-to-week based on recent results but still capture how good a team has been over the whole season. Then, we use these ratings to rank teams. We look at week-to-week movements and longer-term trends. We publish updated power rankings on Tuesdays."
              />
            }
          />
          <Route
            path="/match-analysis"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.type === "Match analysis";
                  })
                }
                userData={userData}
                tagline="Key insights. With pictures..."
                title="Match analysis"
                description="Narratives change quickly in football. A team can put on a tactical masterclass against the league-leaders one week and then collapse to a relegation rival the next. In our tactical reports, we look at a recent match and break down each team's tactics to see which manager made the better decisions. We publish tactical reports the day after selected matches."
              />
            }
          />
          <Route
            path="/team-analysis"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.type === "Team analysis";
                  })
                }
                userData={userData}
                tagline="Who's tactics are the most successful?"
                title="Team analysis"
                description="In our team feature, we take a deep-dive into a team's tactics — formations, possession, pressing, passing, set pieces, and more — over the last few weeks and look at what worked and what didn't. We publish team features on Tuesdays and Thursdays."
              />
            }
          />
          <Route
            path="/player-analysis"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.type === "Player analysis";
                  })
                }
                userData={userData}
                tagline="From scapegoat to hero. And back again..."
                title="Player analysis"
                description="In our player feature series, we take a data-driven look at a player's recent peformances and assess how they've played compared to their peers. We publish player features on Mondays, Wednesdays, and Fridays."
              />
            }
          />
          <Route
            path="/premier-league"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.league === "Premier League";
                  })
                }
                userData={userData}
                tagline="England"
                title="The Premier League"
                description="Haaland, Salah, De Bryne. The Premier League is the world's most popular league and the home to some of the world's biggest stars. Check out our coverage of England's top-flight league."
              />
            }
          />
          <Route
            path="/la-liga"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.league === "La Liga";
                  })
                }
                userData={userData}
                tagline="Spain"
                title="La Liga"
                description="Description here."
              />
            }
          />
          <Route
            path="/bundesliga"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.league === "Bundesliga";
                  })
                }
                userData={userData}
                tagline="Germany"
                title="The Bundesliga"
                description="Description here."
              />
            }
          />
          <Route
            path="/serie-a"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.league === "Serie A";
                  })
                }
                userData={userData}
                tagline="Italy"
                title="Serie A"
                description="Description here."
              />
            }
          />
          <Route
            path="/ligue-1"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.league === "Ligue 1";
                  })
                }
                userData={userData}
                tagline="France"
                title="Ligue 1"
                description="France's top flight is dominated by PSG. Led by global superstar Kylian Mbappe, PSG look to win the EUFA Champion's League."
              />
            }
          />
          <Route
            path="/mls"
            exact
            element={
              <ArticlesPage
                articleData={
                  articleData.filter((item) => {
                    return item.league === "MLS";
                  })
                }
                userData={userData}
                tagline="United States"
                title="MLS"
                description="Boosted by Inter Miami's signing of Messi and a new braodcast deal with Apple, MLS is looking to take its place as one of the world's top football leagues. "
              />
            }
          />
          {articleData.map((item, i) => (
            <Route key={i} path={item.link} exact element={<ArticlePage userData={userData} articleMetadata={item} API={API} articleId={item.id} articleData={articleData.slice(0, 6)} />} />
          ))}
        </Routes>
        <SideMenu />
        <Footer />
      </AppWrapper>
  } else {
    app =
      <UnlockPage>
        <Logo src="/HGIMT-logo.svg" />
        <FormWrapper>
          <TextBox label="Password" value={password} onChange={setPassword} />
        </FormWrapper>
        <FormWrapper>
          <Button style="solid" corners="rounded" label="Unlock" small onClick={unlock} />
        </FormWrapper>
      </UnlockPage>
  }

  return (
    app
  );
};
