import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ArticleCard from "./article-card";
import Button from "./button";
import { GridWrapper, FeatureWrapper } from "./wrapper-elements";
import { SectionTitle, Paragraph } from "./text-elements";
import theme from "./theme.json";

const IconWrapper = styled.div`
  position: relative;
  height: 80px;
  width: 80px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Icon = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
`;

const ShadowIcon = styled.img`
  position: absolute;
  top: 8px;
  left: 8px;
  height: 100%;
  opacity: 0.1;
`;

const Tag = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: white;
  line-height: 1.3;
  padding-bottom: 12px;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
`;

const Feature = (props) => {

  const { title, text, tag, icon, buttonLabel, buttonLink, inverted } = props;

  let iconComponent;
  if (icon) {
    iconComponent =
      <IconWrapper>
        <Icon src={icon} />
        <ShadowIcon src={icon} />
      </IconWrapper>
  }

  let buttonComponent;
  if (buttonLabel) {
    buttonComponent =
      <ButtonWrapper>
        <Button style="outlined" corners="pill" inverted={inverted} label={buttonLabel} to={buttonLink} />
      </ButtonWrapper>
  }

  let titleComponent;
  if (title) {
    titleComponent =
      <SectionTitle $inverted={inverted} >
        {title}
      </SectionTitle>
  }

  let tagComponent;
  if (tag) {
    tagComponent =
      <Tag>
        {tag}
      </Tag>
  }

  const paragraphs = [].concat(text);

  let textComponent;
  if (text) {
    textComponent =
      <div>
        {paragraphs.map((paragraph, i) => {
          return (
            <Paragraph key={i} $inverted={inverted} >{paragraph}</Paragraph>
          )
        })}
      </div>
  }

  return (
    <FeatureWrapper>
      {tagComponent}
      {titleComponent}
      {textComponent}
      {buttonComponent}
    </FeatureWrapper>
  )
}

export default Feature;
