import styled from "styled-components";
import Button from "./button";
import { PageWrapper, FeatureWrapper } from "./wrapper-elements";
import theme from "./theme.json";

// const PageWrapper = styled.div`
//   box-sizing: border-box;
//   width: 100vw;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   min-height: 90vh;
//   padding-top: 100px;
//   padding-bottom: 100px;
// `;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 48px;
  margin-bottom: 96px;
`;

const Title = styled.div`
  display: flex;
  font-weight: 800;
  line-height: 1;
  color: ${theme.color.text.dark};
  margin-bottom: 16px;
  font-size: 24px;
  @media (min-width: 850px) {
    font-size: 48px;
  }
`;

const Tag = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.color.primary.dark};
  line-height: 1;
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${theme.color.text.normal};
  line-height: 1.5;
  margin-bottom: 16px;
`;

const Highlight = styled.span`
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  background: ${theme.color.primary.dark};
  border-radius: 2px;
  font-weight: 600;
  color: white;
  white-space: nowrap;
`;

const AboutPage = () => {

  return (
    <PageWrapper>
      <FeatureWrapper>
        <Wrapper>
          <Tag>
            Welcome!
          </Tag>
          <Title>
            About us
          </Title>
          <Text>
            <Highlight>howgoodismyteam.com</Highlight> publishes quantitative analysis and data visualizations. We currently cover the Premier League, La Liga, the Bundesliga, Serie A, Ligue 1, and MLS.
          </Text>
          <Text>
            We publish industry-leading AI-powered predictions for upcoming matches and articles featuring data visualizations and quantitative analysis about leagues, matches, and players.
          </Text>
          <Text>
            <Highlight>howgoodismyteam.com</Highlight> is owned and operated by Hjartdal Studios LLC, an app development company. Hjartdal Studios LLC was founded by Josh Fjelstul, PhD. Josh is a data scientist and machine learning engineer. He has a PhD in Political Science from Emory University (2019).
          </Text>
          <a href="mailto:support@hjartdalstudios.com">
            <Button style="solid" corners="rounded" primary small label="Contact us" />
          </a>
        </Wrapper>
      </FeatureWrapper>
    </PageWrapper>
  );
};

export default AboutPage;
