import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MenuButton from "./menu-button";

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: unset;
  width: unset;
  height: 96px;
  padding-left: 14px;
  padding-top: 4px;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  @media (min-width: 888px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: unset;
    width: 96px;
    height: unset;
    padding-top: 0px;
    border: none;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding-left: 0px;
  }
`;

const Drawer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  z-index: 2000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-left: 24px;
  background-color: #27272C;
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => props.open ? "1" : "0"};
  overflow-x: hidden;
  pointer-events: ${props => props.open ? "unset" : "none"};
  @media (min-width: 888px) {
    padding-left: 48px;
    padding-left: calc(96px * 2);
  }
`;

const Item = styled(Link)`
  font-size: 24px;
  font-weight: 200;
  font-style: italic;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 4px;
  margin-top: 18px;
  margin-bottom: 12px;
  padding-bottom: 2px;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
  transition: color 0.3s ease-in-out;
  border-bottom: solid 1px #ffffff00;
  &:hover {
    border-bottom: solid 1px #ffffff;
  }
  @media (min-width: 888px) {
    font-size: 50px;
    border-bottom: solid 2px #ffffff00;
    margin-bottom: 18px;
    &:hover {
      border-bottom: solid 2px #ffffff;
    }
  }
`;

const LogoWrapper = styled(Link)`
  position: fixed;
  top: 32px;
  left: 0px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  width: 70%;
`;

const Menu = (props) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <Wrapper>
      {/* <LogoWrapper to="/">
        <Logo src="HGIMT-logo.svg" />
      </LogoWrapper> */}
      <MenuButton onClick={() => setMenuOpen(!menuOpen)} open={menuOpen}/>
      <Drawer open={menuOpen}>
        <Item to="/" onClick={() => setMenuOpen(false)} >
          Home
        </Item>
        {/* <Item to="/leagues" onClick={() => setMenuOpen(false)} >
          Leagues
        </Item>
        <Item to="/coverage" onClick={() => setMenuOpen(false)} >
          Coverage
        </Item> */}
        <Item to="/subscribe" onClick={() => setMenuOpen(false)} >
          Subscribe
        </Item>
        <Item to="/sign-up" onClick={() => setMenuOpen(false)} >
          Sign up
        </Item>
        <Item to="/sign-in" onClick={() => setMenuOpen(false)} >
          Sign in
        </Item>
        <Item to="/about" onClick={() => setMenuOpen(false)} >
          About
        </Item>
      </Drawer>
    </Wrapper>
  );
}

export default Menu;
