import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Section from "./section";
import { PageWrapper, BannerWrapperTitle, TitleWrapper } from "./wrapper-elements";
import theme from "./theme.json";

const Tagline = styled.div`
  font-size: 18px;
  font-weight: 200;
  font-style: italic;
  color: white;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Title = styled.div`
  font-weight: 800;
  line-height: 1.2;
  font-style: italic;
  margin-bottom: 16px;
  color: white;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-bottom: 48px;
  @media (min-width: 776px) {
    font-size: 56px;
  }
`;

const Description = styled.div`
  font-weight: 300;
  line-height: 1.7;
  margin-bottom: 16px;
  color: white;
  font-size: 16px;
  @media (min-width: 776px) {
    font-size: 16px;
  }
`;

const ArticlesPage = (props) => {

  const { articleData, userData, tagline, title, description } = props;

  let page =
    <PageWrapper>
    </PageWrapper>

  if (articleData) {
    page =
      <PageWrapper>
        <BannerWrapperTitle>
          <TitleWrapper>
            <Title>
              {title}
            </Title>
            {/* <Tagline>
              {tagline}
            </Tagline> */}
            <Description>
              {description}
            </Description>
          </TitleWrapper>
        </BannerWrapperTitle>
        <Section
          articleData={articleData}
          userData={userData}
        />
      </PageWrapper >
  }

  return (
    page
  )
}

export default ArticlesPage;
