import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "./button";
import Section from "./section";
import Card from "./card";
import Chip from "./chip";
import Feature from "./feature";
import { PageWrapper, GridWrapper, TitleWrapper, BannerWrapperTitle, BannerWrapperLight, BannerWrapperDark } from "./wrapper-elements";
import { SectionTitle } from "./text-elements";
import theme from "./theme.json";

const Tagline = styled.div`
  font-size: 16px;
  font-weight: 600;
  // font-style: italic;
  // color: ${theme.color.primary.dark};
  color: white;
  line-height: 1.3;
  margin-bottom: 16px;
  @media (min-width: 888px) {
    font-size: 22px;
  }
`;

const Title = styled.div`
  font-weight: 800;
  font-style: italic;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 56px;
  // color: ${theme.color.text.dark};
  color: white;
  font-size: 24px;
  // transform: skew(-15deg);
  @media (min-width: 888px) {
    font-size: 56px;
  }
`;

const Description = styled.div`
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 12px;
  // color: ${theme.color.text.normal};
  color: white;
  font-size: 16px;
  @media (min-width: 888px) {
    font-size: 16px;
  }
`;

const Highlight = styled.span`
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 6px;
  padding-right: 6px;
  // background: ${theme.color.primary.dark};
  background-color: rgba(255, 255, 255, 0.9);
  // border-radius: 2px;
  font-weight: 500;
  color: ${theme.color.text.dark};
  // color: ${theme.color.primary.dark};
  white-space: nowrap;
`;

const ButtonWrapper = styled.div`
  padding-top: 12px;
`;

const BannerGradient = styled.div`
  position: absolute;
  top: 0px;
  bottom: 50px;
  z-index: -1;
  width: 100vw;
  background: linear-gradient(
    110deg, 
    #0069FF 0%,
    #FFFFFF 50%,
    #FF50A6 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 500%;
  animation: background 10s linear alternate infinite;
  @keyframes background {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
  }
`;

// const SectionTitle = styled.div`
//   box-sizing: border-box;
//   display: flex;
//   justify-content: flex-start;
//   align-items: flex-start;
//   font-weight: 800;
//   line-height: 1.3;
//   color: white;
//   font-size: 24px;
//   padding-left: 12px;
//   padding-right: 12px;
//   padding-bottom: 12px;
//   width: 100%;
//   @media (min-width: 888px) {
//     font-size: 40px;
//   }
// `;

const AccentWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Accent = styled.div`
  height: 4px;
  width: 24px;
  background-color: ${props => props.color};
  border-radius: 16px;
  margin-right: 12px;
  @media (min-width: 888px) {
    width: 48px;
  }
`;

const Separator = styled.div`
  height: 48px;
  @media (min-width: 888px) {
    height: 96px;
  }
`;

const CoverageWrapper = styled.div`
  background-color: ${theme.color.background};
`;

const CoverageItem = styled(Link)`
  width: 336px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;
  background: ${theme.color.background};
  font-size: 18px;
  font-weight: 600;
  color: ${theme.color.text.dark};
  padding-top: 32px;
  padding-bottom: 32px;
  border-radius: 16px;
  transition: ease-in-out 0.2s;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0);
  transform: scale(1);
  &:hover {
    transform: scale(1.03);
  }
`;

const HomePage = (props) => {

  const { articleData, userData } = props;

  let page =
    <PageWrapper>

    </PageWrapper>

  if (articleData) {
    page =
      <PageWrapper>
        <BannerWrapperTitle>
          <TitleWrapper>
            <Tagline>
              footballdataviz.com
            </Tagline>
            <Title>
              Your visual guide to football
            </Title>
            <Description>
              Keep up with the world's most popular football leagues with <Highlight>AI-powered predictions</Highlight> and <Highlight>intuitive data visualization.</Highlight> Starting at <Highlight>only $2 a month.</Highlight>
            </Description>
            <ButtonWrapper>
              <Button style="solid" corners="pill" label="Subscribe" inverted as={Link} to="/subscribe" />
            </ButtonWrapper>
          </TitleWrapper>
        </BannerWrapperTitle>
        {/* <BannerWrapperLight>
          <GridWrapper>
            <CoverageItem to="match-predictions">
              Match predictions
            </CoverageItem>
            <CoverageItem to="league-predictions">
              League predictions
            </CoverageItem>
            <CoverageItem to="fixture-guides">
              Fixture guides
            </CoverageItem>
            <CoverageItem to="match-previews">
              Match previews
            </CoverageItem>
            <CoverageItem to="match-reports">
              Match reports
            </CoverageItem>
            <CoverageItem to="power-rankings">
              Power rankings
            </CoverageItem>
            <CoverageItem to="match-analysis">
              Match analysis
            </CoverageItem>
            <CoverageItem to="team-analysis">
              Team analysis
            </CoverageItem>
            <CoverageItem to="player-analysis">
              Player analysis
            </CoverageItem>
          </GridWrapper>
        </BannerWrapperLight> */}
        {/* <TitleWrapper>
          <AccentWrapper>
            <Accent color="#0064b4" />
            <Accent color="#6388c7" />
            <Accent color="#9aaeda" />
            <Accent color="#dc5078" />
            <Accent color="#ea8098" />
            <Accent color="#f4abb9" />
          </AccentWrapper>
        </TitleWrapper> */}
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Match predictions";
            })
          }
          userData={userData}
          title="Latest articles"
          buttonLabel="See all"
          buttonLink="/latest"
          count={9}
        />
        <BannerWrapperDark>
          <TitleWrapper>
            <SectionTitle $inverted >
              Leagues
            </SectionTitle>
          </TitleWrapper>
          <GridWrapper>
            <Card text="Premier League" size="large" image="/maps/england-map-white.svg" to="/premier-league" />
            <Card text="La Liga" size="large" image="/maps/spain-map-white.svg" to="/la-liga" />
            <Card text="Bundesliga" size="large" image="/maps/germany-map-white.svg" to="/bundesliga" />
            <Card text="Serie A" size="large" image="/maps/italy-map-white.svg" to="/serie-a" />
            <Card text="Ligue 1" size="large" image="/maps/france-map-white.svg" to="/ligue-1" />
          </GridWrapper>
        </BannerWrapperDark>
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Match predictions";
            })
          }
          userData={userData}
          title="Match predictions"
          buttonLabel="See all"
          buttonLink="/match-predictions"
          count={9}
        />
        <BannerWrapperDark>
          <Feature
            title="Learn about football analytics"
            text="Interested in learning more about football analytics but don't know where to start? Take a look at our friendly introduction to football analytics."
            buttonLabel="Get started"
            buttonLink="/guide-to-football-analytics"
            inverted
          />
        </BannerWrapperDark>
        {/* <SmallBannerWrapper dark>
          <TitleWrapper>
            <Title>
              Coverage
            </Title>
          </TitleWrapper>
          <GridWrapper>
            <TextItem to="match-predictions">
              Match predictions
            </TextItem>
            <TextItem to="league-predictions">
              League predictions
            </TextItem>
            <TextItem to="fixture-guides">
              Fixture guides
            </TextItem>
            <TextItem to="match-previews">
              Match previews
            </TextItem>
            <TextItem to="match-reports">
              Match reports
            </TextItem>
            <TextItem to="power-rankings">
              Power rankings
            </TextItem>
            <TextItem to="match-analysis">
              Match analysis
            </TextItem>
            <TextItem to="team-analysis">
              Team analysis
            </TextItem>
            <TextItem to="player-analysis">
              Player analysis
            </TextItem>
          </GridWrapper>
        </SmallBannerWrapper> */}
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "League predictions";
            })
          }
          userData={userData}
          title="League predictions"
          buttonLabel="See all"
          buttonLink="/league-predictions"
          count={6}
        />
        {/* <SmallBannerWrapper dark>
          <TitleWrapper>
            <Title>
              Teams
            </Title>
          </TitleWrapper>
          <GridWrapper>
            <TextItem to="">
              Liverpool
            </TextItem>
            <TextItem to="">
              Manchester City
            </TextItem>
            <TextItem to="">
              Tottenham
            </TextItem>
            <TextItem to="">
              Manchester United
            </TextItem>
            <TextItem to="">
              Real Madrid
            </TextItem>
            <TextItem to="">
              Barcelona
            </TextItem>
            <TextItem to="">
              Paris Saint Germain
            </TextItem>
            <TextItem to="">
              Inter
            </TextItem>
            <TextItem to="">
              Milan
            </TextItem>
            <TextItem to="">
              Juventus
            </TextItem>
          </GridWrapper>
        </SmallBannerWrapper> */}
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Fixture guide";
            })
          }
          userData={userData}
          title="Fixture guides"
          buttonLabel="See all"
          buttonLink="/fixture-guides"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Match preview";
            })
          }
          userData={userData}
          title="Match previews"
          buttonLabel="See all"
          buttonLink="/match-previews"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Match report";
            })
          }
          userData={userData}
          title="Match reports"
          buttonLabel="See all"
          buttonLink="/match-reports"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Power rankings";
            })
          }
          userData={userData}
          title="Power rankings"
          buttonLabel="See all"
          buttonLink="/power-rankings"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Match analysis";
            })
          }
          userData={userData}
          title="Match analysis"
          buttonLabel="See all"
          buttonLink="/match-analysis"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Team analysis";
            })
          }
          userData={userData}
          title="Team analysis"
          buttonLabel="See all"
          buttonLink="/team-analysis"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Player analysis";
            })
          }
          userData={userData}
          title="Player analysis"
          buttonLabel="See all"
          buttonLink="/player-analysis"
          count={6}
        />

      </PageWrapper >
  }

  return (
    page
  )
}

export default HomePage;
