import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./app";
import ScrollToTop from "./scroll-to-top";
import "./app-styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
    <App />
  </BrowserRouter>
);
